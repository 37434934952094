/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

class IssuesForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/mpzoykge"
        method="POST"
        className="col-12"
      >
        <h2>Kontakta oss</h2>
        <input type="hidden" name="_subject" value="Problembeteende formulär" />
        <div className="form-group">
          <label>Namn:</label>
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Skriv ditt namn.."
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Mobil:</label>
              <input
                type="tel"
                name="phone"
                className="form-control"
                placeholder="t.ex. 0701234567"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>E-post:</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Skriv in din e-post adress.."
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Meddelande:</label>
          <textarea
            name="message"
            placeholder="Beskriv vilket beteende vi kan hjälpa till att förbättra.."
            className="form-control"
          />
        </div>
        {status === 'SUCCESS' ? <p>Tack för ditt meddelande!</p> : <button className="btn btn-action-rose" type="submit">Skicka</button>}
        {status === 'ERROR' && <p>Något gick fel. Pröva igen.</p>}
      </form>
    );
  }
}

export default IssuesForm;
