import React from 'react';

const Practical = () => (
  <div className="row">
    <div className="col-12">
      <h2>Praktisk info</h2>
      <p>
        År 2001 öppnade vi och är idag ett av Sveriges hunddagis som funnits längst och det innebär även en gedigen kunskap och erfarenhet inom yrket.
      </p>
      <p>
        <a href="https://www.facebook.com/Nackahunddagis/">
          {' '}
          <i className="fab fa-facebook" />
          {' '}
          Nacka Hunddagis
        </a>
      </p>
      <p>
        <a className="btn btn-lg btn-action-rose mr-2" href="mailto:nacka.hunddagis31@outlook.com">Maila oss</a>
        <a className="btn btn-lg btn-action-rose mr-2" href="https://goo.gl/maps/wyg1wDBCjHPztx7v8">Hitta hit</a>
      </p>
      <p>
        Priser:
        <br />
        Heltid 5 dagar/vecka: 3700:- /månad
        <br />
        Deltid 1-3 valfria dagar i veckan: 3350:- /månad
        <br />
        Enstaka dagar, valfria: 550 :-/dag.
        <br />
        Kurs, en timme: 850 :-/timme.
        <br />
      </p>
      <p>
        <b>Öppet mellan 7-18.</b>
        <br />
      </p>
      <p>
        Vi är 4 st. som arbetar här och en ekonomiansvarig.
        <br />
        Vi är ett medelstort hunddagis.
        <br />
        Vi delar upp hundarna i storlek för säkerhets skull. Alla våra hundar är snälla och ingen är aggressiv.
        <br />
        Vi försöker ha hundarna ute så mycket som möjligt om dagarna.
        <br />
        Vi har 2 kalendermånaders uppsägningstid. Dock alltid en provmånad utan någon uppsägningstid.
        <br />
        Vi har stängt 4 veckor från 1 Juli och 3 veckor vid Jul och Nyår.
        <br />
      </p>
      <p>
        <u>Vi tar emot löptikar,</u>
        {' '}
        då vi har krav på att alla hanar måste vara kirurgiskt kastrerade. Min erfarenhet kring kastrering av hanar är mycket stor och vi har enbart positiva erfarenheter av kastrering. Dom blir både gladare och mer harmoniska, precis som övriga djurarter.
      </p>
      <p>
        Hoppas vi ses.
        <br />
        Med vänliga hälsningar
        <br />
        Team Nacka hunddagis
        <br />
      </p>
    </div>
  </div>
);

export default Practical;
