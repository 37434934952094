import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DynamicPostsView from '../../components/DynamicPostsView';
import DynamicPostForm from '../../components/DynamicPostForm';

import StartView from './StartView';
import AboutEnglish from './AboutEnglish';
import RaceInfo from './RaceInfo';
import About from './About';
import KatteriNav from './KatteriNav';

import SverakLogo from '../../resources/sverak.png';

const Katteri = () => (
  <div className="container-fluid katteri py-4">
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 py-2">
          <h1>Katteri</h1>
        </div>
      </div>
      <KatteriNav />
      <div className="row">
        <div className="col-12">
          <Switch>
            <Route exact path="/katteri/start">
              <StartView />
              <DynamicPostsView title="Planerade kullar" area="katteri" lang="sv" />
            </Route>
            <Route exact path="/katteri/new">
              <DynamicPostForm area="katteri" />
            </Route>
            <Route path="/katteri/post/edit/:editid">
              <DynamicPostForm area="katteri" />
            </Route>
            <Route exact path="/katteri/till-salu">
              <DynamicPostsView title="Till salu" area="catssale" lang="sv" />
            </Route>
            <Route exact path="/katteri/till-salu/new">
              <DynamicPostForm area="catssale" />
            </Route>
            <Route exact path="/katteri/till-salu/post/edit/:editid">
              <DynamicPostForm area="catssale" />
            </Route>
            <Route exact path="/katteri/om">
              <About />
            </Route>
            <Route exact path="/katteri/rasbeskrivning">
              <RaceInfo />
            </Route>
            <Route exact path="/katteri/my-cats">
              <DynamicPostsView title="My cats" area="katteri" lang="en" />
            </Route>
            <Route exact path="/katteri/for-sale">
              <DynamicPostsView title="For Sale" area="catssale" lang="en" />
            </Route>
            <Route exact path="/katteri/about-us">
              <AboutEnglish />
            </Route>
          </Switch>
          <div className="row">
            <div className="col-12 py-2 text-center">
              <img src={SverakLogo} className="sverak-logo" alt="Svenska Kennelklubben logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Katteri;
