import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => (
  <div className="nav-container">
    <div className="container">
      <div className="row d-flex justify-content-center">
        <nav className="col-12 col-sm-10 col-md-8 d-flex align-items-start">
          <NavLink className="btn btn-nav" to="/hem">
            Hem
          </NavLink>
          <NavLink className="btn btn-nav" to="/information/praktisk">
            Hunddagis
          </NavLink>
          <NavLink className="btn btn-nav" to="/kennel/start">
            Hundsida
          </NavLink>
          <NavLink className="btn btn-nav" to="/katteri/start">
            Katteri
          </NavLink>
        </nav>
      </div>
    </div>
  </div>
);

export default Nav;
