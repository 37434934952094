import React from 'react';

const RaceInfo = () => (
  <div className="row d-flex justify-content-center">
    {/* <div className="col-12 col-sm-10 col-md-8 col-lg-8">
      <h2>Italiensk vinthund</h2>
      <p>
        Historik
        <br />
        Troligtvis härstammar denna eleganta varelse från de egyptiska vinthundarna. Från vår tideräknings början har dvärgvinthundar avbildats på konstföremål och målningar i poser som klart förknippas med rasen. Hundar med bakbenen indragna under kroppen, liggande hundar med de smala, finlemmade frambenen korslagda framför sig eller där ena frambenet är uppdraget. Italiensk vinthund har använts till jakt på småvilt, men även som populär sällskapshund och är utformad på kontinenten, främst i Italien, Tyskland och Österrike.
      </p>
      <p>
        Användningsområde
        <br />
        I huvudsak en sällskaps- och familjehund.
        <br />
        Men det finns representanter av rasen i många olika tävlingsgrenar såsom utställning, lure coursing agility och lydnad.
        <br />
        I flera länder används rasen som kapplöpningshund, dock inte här i Sverige.
        <br />
        Lure coursing är Italienska vinthundens bruksprov kan man säga.
      </p>
      <p>
        Hälsa
        <br />
        Rasen är relativt frisk och sund, men viktigt att uppfödaren du väljer tar sitt ansvar och hälsotestar avelshundarna för dom vanligaste sjukdomarna.
        <br />
        <br />
        Väldigt bra information om det finns att se på Svenska Kennelklubbens sida. Skriv in hundens information så får ni upp veterinärdata.
        {' '}
        <a href="https://hundar.skk.se/hunddata/Hund.aspx?hundid=3319637" target="_blank" rel="noreferrer">https://hundar.skk.se/hunddata/Hund.aspx?hundid=3319637</a>
      </p>
      <p>
        Egenskaper / Mentalitet
        <br />
        Den italienska vinthunden är den storleksmässigt minsta representanten i gruppen vinthundar. Den är en livlig, glad och positiv liten varelse, kan vara reserverad.
        <br />
        <br />
        En härlig lättskött sällskapshund och fantastiskt kelig ras.
        <br />
        <br />
        Den är i grunden en mycket sund hundras.
        <br />
        <br />
        Det enda man egentligen måste skydda den emot är just dess eget mod, framför allt hos små valpar. De kan till exempel kasta sig oförskräckt utför soffor och andra höga höjder.
      </p>
      <p>
        Storlek och utseende
        <br />
        Det är en kurvig liten vinthund med lång, vacker hals och högt buret huvud. Den är högbent och kvadratisk. Mankhöjd är 32–38 cm. Max vikt 5 kg. Ryggen är lätt välvd med ett sluttande kors. Bakbenen är väl vinklade och får gärna visa de rastypiska S-kurvorna. Karaktäristiskt för rasen är dess dansande rörelser. Den tunna huden är täckt med kort, fin päls. Enligt rasstandarden är tillåtna färger enfärgat svart, skiffergrått och isabella-cream i alla nyanser. Vita tecken får endast förekomma på tassar och bringa.
      </p>
      <p>
        Pälsvård
        <br />
        Lättskött och badas vid behov. Fäller väldigt lite.
        <br />
        <br />
        Efter att haft många olika raser, så inser jag att denna ras är absolut min favorit.
        <br />
        Ingen dyr krävande pälsvård varje månad och enligt mig den mest kärleksfulla, roligaste rasen.
        <br />
      </p>
      <p>
        Hoppas vi hörs
        <br />
        <br />
        Med vänliga hälsningar
        <br />
        Anna med familj
      </p>
    </div> */}
  </div>
);

export default RaceInfo;
