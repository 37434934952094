import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './containers/home';
// import Header from './containers/header';
import Nav from './containers/nav';
import Footer from './containers/footer';
import Information from './containers/information';
import Katteri from './containers/katteri';
import Kennel from './containers/kennel';
import Blog from './containers/blog';
import ScrollToTop from './components/ScrollToTop';

const App = () => (
  <Router>
    <ScrollToTop />
    {/* <Header /> */}
    <Nav />
    <div id="body-container">
      <Switch>
        <Route path="/blog" component={Blog} />
        <Route path="/katteri" component={Katteri} />
        <Route path="/kennel" component={Kennel} />
        <Route path="/information" component={Information} />
        <Route path="/hem" component={Home} />
        <Route path="/" exact component={Home} />
      </Switch>
    </div>
    <Footer />
  </Router>
);

export default App;
