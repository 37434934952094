import React from 'react';

const AboutEnglish = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 col-lg-8">
      <h2>About us</h2>

      <p>
        Puppies and kittens that grow up with us get lots of love and social training. We also give the puppies fresh food of various kinds, as research has shown that it benefits the puppy&quot;s health in the future.
      </p>

      <p>
        Each litter is carefully planned and long awaited.
      </p>
      <p>
        I want to offer you my knowledge in dog and cat both before and after the purchase.
      </p>
      <p>
        I have been the owner of Nacka dog day-care since 2001, so if there is a need for dog care, I want to offer my services.
      </p>
      <p>
        Health testing of both parents is very important to us and will be a security for your buyers.
      </p>
      <p>
        We do eye exams, patella and DNA tests for dental disease.
      </p>
      <p>
        Sales are done with careful research, and I will have a small course for you, as I am a trained dog trainer and competed a lot in obedience.
      </p>
      <p>
        We are 4 girls who help to take care of puppies &amp; kittens.
      </p>
      <p>
        Sincerely,
        <br />
        Anna with family.
      </p>
    </div>
  </div>
);

export default AboutEnglish;
