import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Post from '../../components/post';
// import LargePost from '../../components/LargePost';
import { withFirebase } from '../../components/firebase';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null,
    };
  }

  componentDidMount() {
    const { firebase } = this.props;

    firebase.getPosts().limitToLast(1).once('value', (snapshot) => {
      const postObject = snapshot.val();
      try {
        const post = Object.keys(postObject).map((key) => ({
          ...postObject[key],
          id: key,
        }));

        this.setState({
          post: post[0],
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  render() {
    const {
      post,
    } = this.state;
    return (
      <div className="container-fluid py-4">
        <div className="container">
          <div className="row d-flex justify-content-center first-story">
            {/* <LargePost
              heading="Hunddagis"
              description="Vi tar gärna emot din ansökan om dagisplats. Vi erbjuder Heltid och Deltid. Helt valfria dagar. Även enstaka dagar."
            >
              <p>
                <a href="https://www.facebook.com/Nackahunddagis/">
                  {' '}
                  <i className="fab fa-facebook" />
                  {' '}
                  Nacka Hunddagis
                </a>
              </p>
              <a className="btn btn-lg btn-action-rose mr-2" href="mailto:anna_ostring@hotmail.com">Maila</a>
              <Link className="btn btn-lg btn-action" to="/information/praktisk"> Läs mer</Link>
            </LargePost> */}
            {/* <div className="col-10 pb-2 py-md-4">
              <hr />
            </div> */}
            <Post
              imgSrc="https://firebasestorage.googleapis.com/v0/b/nacka-hunddagis.appspot.com/o/resources%2F830a72d4-e644-4c35-8ea9-eb9aac006dbc.jpeg?alt=media&token=46816eb1-bdaa-4952-8644-4ebc8f656f21"
              heading="Välkommen"
              description="Här hittar du information om Nacka hunddagis, Stjärnströssels katteri och Nosgårdens hundsida"
            />
            {!post ? (
              <Post
                heading="Aktuellt"
                description=" "
                // description="Spana in aktuellt och få reda på vad vi gör om dagarna."
              >
                <div className="text-left">
                  <Link className="btn btn-lg btn-action-rose" to="/blog">
                    Till aktuellt
                  </Link>
                </div>
              </Post>
            ) : (
              <>
                <div className="col-12 col-sm-10 col-md-8 pt-2">
                  <h2>Aktuellt</h2>
                </div>
                <Post
                  linkToPost
                  truncateText
                  key={post.date}
                  heading={post.title}
                  description={post.text}
                  date={post.date}
                  edited={post.editDate}
                  imgSrc={post.imageSrc}
                >
                  <div className="text-left">
                    <Link className="btn btn-lg btn-action-rose" to="/blog">
                      Till aktuellt
                    </Link>
                  </div>
                </Post>
              </>
            )}
            <Post
              imgSrc="https://firebasestorage.googleapis.com/v0/b/nacka-hunddagis.appspot.com/o/resources%2F244538796_2968186806727862_619489983764291760_n.jpg?alt=media&token=9a62f168-9983-4a9b-8a8e-f098b37799e6"
              heading="Avel/ Breeding"
              description="Här hittar ni information och uppdarteringar om vårat katteri och våran hundsida. Here you will find information and updates about our cattery and kennel."
            >
              <div className="text-right mb-2">
                <Link className="btn btn-lg btn-action mr-1" to="/katteri/start">
                  <span className="flag-icon flag-icon-se" />
                  <span> Katteri</span>
                </Link>
                <Link className="btn btn-lg btn-action" to="/kennel/start">
                  <span className="flag-icon flag-icon-se" />
                  <span> Hundsida</span>
                </Link>
              </div>
              <div className="text-right">
                <Link className="btn btn-lg btn-action mr-1" to="/katteri/my-cats">
                  <span className="flag-icon flag-icon-gb" />
                  <span> Cattery</span>
                </Link>
                <Link className="btn btn-lg btn-action" to="/kennel/my-dogs">
                  <span className="flag-icon flag-icon-gb" />
                  <span> Kennel</span>
                </Link>
              </div>
            </Post>
            <Post
              imgSrc="https://firebasestorage.googleapis.com/v0/b/nacka-hunddagis.appspot.com/o/resources%2Fproblembeteende2.jpg?alt=media&token=f3984817-71ee-452e-80e8-8cb4a61f1038"
              heading="Problembeteende"
              description="Gör din hund utfall eller har utvecklat något beteende som är svårt
      att hantera? Jag kan erbjuda dig och din hund professionell hjälp."
            >
              <div className="text-left">
                <Link className="btn btn-lg btn-action" to="/information/problem"> Läs mer</Link>
              </div>
            </Post>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
};

export default withFirebase(Home);
