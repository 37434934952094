/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';

import { compose } from 'recompose';
import { withFirebase } from '../../components/firebase';
// import ScrollToTopOnMount from '../../components/ScrollTopOnMount';

import 'lazysizes';

class PostViewBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      title: '',
      text: '',
      imageSrc: null,
      date: '',
      editDate: '',
      windowHeight: 0,
    };
  }

  componentDidMount() {
    const { firebase, match } = this.props;
    this.setState({
      loading: true,
      windowHeight: window.innerHeight,
    });
    if (match.params.id) {
      firebase.getPost(match.params.id).once('value').then((snapshot) => {
        const post = snapshot.val();
        this.setState({
          ...post,
          loading: false,
        });
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const {
      loading,
      title,
      text,
      imageSrc,
      date,
      editDate,
      windowHeight,
    } = this.state;

    if (!loading) {
      return (
        <>
          {/* <ScrollToTopOnMount /> */}
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 py-2 pt-md-1">
              <div className="row py-2">
                <div className="col-12">
                  <Link className="btn btn-action-rose" to="/blog">
                    <i className="fa fa-chevron-left" />
                    {' '}
                    Aktuellt
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {imageSrc && (
                    <img
                      alt={title}
                      className="img-fluid lazyload mb-3"
                      data-src={imageSrc}
                    />
                  )}
                </div>
                <div className="col-12">
                  <h1 className="mb-1">{title}</h1>
                  {date && (
                    <p className="post-date mt-1 mb-3">
                      Skrivet:
                      {' '}
                      {moment(date).format('YYYY-MM-DD HH:mm')}
                      {editDate && (
                        <span className="post-date text-muted">
                          {' '}
                          Uppdaterat:
                          {' '}
                          {moment(editDate).format('YYYY-MM-DD HH:mm')}
                        </span>
                      )}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  {text.split('\n').map((item, index) => (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="col-12 text-center" style={{ height: windowHeight }}>
        <p>Hämtar inlägg..</p>
        <div className="spinner-grow" role="status">
          <span className="sr-only">Hämtar inlägg..</span>
        </div>
      </div>
    );
  }
}
PostViewBase.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

const PostView = compose(
  withRouter,
  withFirebase,
)(PostViewBase);

export default PostView;
