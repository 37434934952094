import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import './index.css';
import App from './App';

import { AppProvider } from './context/appContext';

import Firebase, { FirebaseContext } from './components/firebase';

ReactDOM.render(
  <AppProvider>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </AppProvider>,
  document.getElementById('root'),
);
