import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // const scrollToTop = window.sessionStorage.getItem('scrollToTop');
    // if (!scrollToTop) {
    //   window.sessionStorage.setItem('scrollToTop', window.innerHeight);
    //   window.scrollTo(0, 0);
    // } else {
    //   window.scrollTo(0, scrollToTop);
    // }
  }, [pathname]);

  return null;
}
