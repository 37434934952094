import React from 'react';
import IssuesForm from '../../components/IssueForm';

const Issues = () => (
  <>
    <div className="row">
      <div className="col-12">
        <h2>Problembeteende</h2>
        <p>
          Har du problem med din hund på olika vis så hjälper jag dig gärna. Vi erbjuder en privat onlinekurs eller att vi bokar möte på en timme för 650 kr.
        </p>
        <p>
          Jag är både utbildad hundinstruktör sen 20 år tillbaka drygt och har daglig erfarenhet av att lösa problem hos våra 4 fotade vänner och vet att det till 99% handlar om den som håller i snöret. Så viktigast är att se dig eller hundens familj.
        </p>
        <p>
          Tveka inte, utan hör av er, jag finns här och vill hjälpa er.
          <br />
          Det ska vara skoj att ha hund!
        </p>
      </div>
    </div>
    <div className="row">
      <IssuesForm />
    </div>
  </>
);

export default Issues;
