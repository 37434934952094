import React from 'react';
import {
  NavLink, Route, Switch,
} from 'react-router-dom';
import About from './about';
import Dogcare from './dogcare';
import Issues from './issues';
import Practical from './practical';

const Information = () => (
  <div className="container-fluid py-4">
    <div className="container pt-2">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-8">
          <h1>Nacka Hunddagis</h1>
        </div>
        <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
          <hr />
        </div>
        <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
          <NavLink className="d-block d-lg-inline mr-2" to="/information/praktisk">Praktisk information</NavLink>
          <NavLink className="d-block d-lg-inline mr-2" to="/information/om">Om mig</NavLink>
          <NavLink className="d-block d-lg-inline mr-2" to="/information/endag">En dag på dagiset</NavLink>
          <NavLink className="d-block d-lg-inline" to="/information/problem">Problembeteende</NavLink>
        </div>
        <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
          <hr />
        </div>
        <div className="col-12 col-sm-10 col-md-8 col-lg-8">
          <Switch>
            <Route path="/information/om">
              <About />
            </Route>
            <Route path="/information/endag">
              <Dogcare />
            </Route>
            <Route path="/information/praktisk">
              <Practical />
            </Route>
            <Route path="/information/problem">
              <Issues />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  </div>
);

export default Information;
