import React from 'react';
import { Link } from 'react-router-dom';

const StartView = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 py-2">
      <h2>Nosgården</h2>
      <p>
        Information om tillgängliga valpkullar hittar du här på
        {' '}
        <Link to="/blog">aktuellt</Link>
        {' '}
        och Instagram.
      </p>
      <p>
        Våra hundar är försäkrade i
        {' '}
        <a href="https://www.svedea.se" target="_blank" rel="noreferrer">Svedea</a>
        . Valparna är Dolda fel -försäkrade hos dom vid leverans.
      </p>
      <p>
        <a href="https://www.instagram.com/italiangreyhoundnackahunddagis/" target="_blank" rel="noreferrer">
          {' '}
          <i className="fab fa-instagram" />
          {' '}
          Nosgårdens hundsida
        </a>
      </p>
      <p>
        <a href="https://www.facebook.com/people/SEStj%C3%A4rnstr%C3%B6ssels-katteri/100085489051198/" target="_blank" rel="noreferrer">
          {' '}
          <i className="fab fa-facebook" />
          {' '}
          Nosgårdens hundsida
        </a>
      </p>
    </div>
  </div>
);

export default StartView;
