/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'lazysizes';
import { Link } from 'react-router-dom';

import placeHolder from '../../resources/images/marcel-strauss-iCR53oVMqcs-unsplash.jpg';

const Post = ({
  linkToPost, truncateText, heading, images, description, date, prices, imgSrc, links, children,
}) => (
  <>
    <div className="col-12 col-sm-10 col-md-8 py-2 py-md-4">
      <div className="row">
        {imgSrc && heading && description && (
          <>
            <div className="col-12 col-md-6 pb-3 pb-md-0">
              <img
                alt={heading}
                className="img-fluid lazyload"
                data-src={imgSrc}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="post-header">
                {linkToPost ? <h2><Link to={`/blog/post/${date}`}>{heading}</Link></h2> : <h2>{heading}</h2>}
                {date && (
                  <p className="post-date mt-2 mb-0">
                    Skrivet:
                    {' '}
                    {moment(date).format('YYYY-MM-DD HH:mm')}
                  </p>
                )}
              </div>
              {prices && (
                <>
                  <b>Priser: </b>
                  <ul>
                    {prices.map((price) => <li key={price.id}>{price.value}</li>)}
                  </ul>
                </>
              )}
              {truncateText ? (
                <div className="post-text">
                  {description}
                </div>
              ) : description.split('\n').map((item, index) => (
                <span key={index}>
                  {item}
                  <br />
                </span>
              ))}
              {linkToPost && <Link to={`/blog/post/${date}`}>Läs vidare..</Link>}
            </div>
            <div className="col-12 mt-2">
              {children}
            </div>
          </>
        )}
        {!imgSrc && heading && description && (
          <>
            <div className="col-12">
              {linkToPost ? <h2><Link to={`/blog/post/${date}`}>{heading}</Link></h2> : <h2>{heading}</h2>}
              {images && (
                <div className="row">
                  {images.map((image) => (
                    <div className="col" key={image.id}>
                      <img
                        className="img-fluid lazyload"
                        alt="Bild"
                        data-src={image.src ? image.src : placeHolder}
                      />
                    </div>
                  ))}
                </div>
              )}
              {date && (
                <p className="post-date mt-2 mb-0">
                  Skrivet:
                  {' '}
                  {moment(date).format('YYYY-MM-DD HH:mm')}
                </p>
              )}
              {prices && (
                <>
                  <b>Priser: </b>
                  <ul>
                    {prices.map((price) => <li key={price.id}>{price.value}</li>)}
                  </ul>
                </>
              )}
              {links?.length > 0 && (
                <div className="post-links mt-2">
                  <b>Länkar:</b>
                  {links.map((link, i) => (
                    <p key={`${i}_link`}>
                      <a href={link.href} target="_blank" rel="noreferrer">{link.text}</a>
                    </p>
                  ))}
                </div>
              )}
              {truncateText ? (
                <div className="post-text mt-2">
                  {description}
                </div>
              ) : description.split('\n').map((item, index) => (
                <span key={index}>
                  {item}
                  <br />
                </span>
              ))}
              {linkToPost && <Link to={`/blog/post/${date}`}>Läs vidare..</Link>}
            </div>
            <div className="col-12 mt-2">
              {children}
            </div>
          </>
        )}
      </div>
    </div>
    <div className="col-10 py-2 py-md-4">
      <hr />
    </div>
  </>
);

Post.propTypes = {
  linkToPost: PropTypes.bool,
  truncateText: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  prices: PropTypes.instanceOf(Array),
  links: PropTypes.instanceOf(Array),
  imgSrc: PropTypes.string,
  images: PropTypes.instanceOf(Array),
  children: PropTypes.element,
};

Post.defaultProps = {
  linkToPost: false,
  truncateText: false,
  imgSrc: null,
  date: null,
  prices: null,
  images: null,
  children: null,
  links: null,
};

export default Post;
