import React from 'react';

const About = () => (
  <div className="row">
    <div className="col-12">
      <h2>Om mig</h2>
      <p>
        Hej. Anna heter jag och har arbetat med djur i hela mitt liv på många olika vis.
        <br />
        Kortfattat:
        <br />
        - djuraffär
        <br />
        - veterinärassistent
        <br />
        - hundpensionat
        <br />
        - hunddagis
        <br />
        - kurser
        <br />
        - tjänstehunds placerat en hund
        <br />
        - uppfödare
        <br />
        - mentalfigurant, hundens mentalitet är oerhört viktigt för mig
        <br />
        - tävlat lydnad upp till klass 3 med flera av mina hundar. En sheltie, schäfer, pudel & flatcoated retriever. Bevakning placerade min schäfer i flygvapnet.
        <br />
      </p>
      <p>
        Tränade även en hel del agility, spår, skydds, sök och IPO. Provade på räddning med en av mina flatcoated retrievers också. Min första kull hade jag år 2005 på min andra flat Ayla.
        <br />
        Vi hade fått första pris i Jakt och var uppe i lydnadsklass 3. En härlig hund, som gav mig 8 friska valpar som jag följde i flera år och vi hade valpträffar regelbundet.
        <br />
        Så kul att dela med mig av min kunskap och älskar att finnas där för mina valpköpare.
        <br />
      </p>
      <p>
        Min andra kull var en på min pudelflicka Enya. Blev en fin svart pudelflicka som min granne köpte. År 2001 startade jag även Nackas första hunddagis.
        Fantastiskt yrke och jag arbetar än idag med det och trivs oerhört bra.
      </p>
      <p>
        Idag har jag rasen Italiensk vinthund. Tränat deras bruksgren lite, Lure coursing och ställt ut en del med excellent. Jag är mycket noga med att göra olika hälsoundersökningar innan aveln. Hellre att du vet vad som finns i din hund, med risken att få ta bort den ur aveln än att du struntar i hälsoundersökningar och enbart tänker på hur söta valparna blir.
        <br />
        Jag månar både för er valpköpare och mig själv. Då jag personligen har erfarenhet av att köpa hund med sjukdomar som lätt kunnat undvika med rätt avelsprogram eller undersökningar.
        <br />
        DNA prov finns till vår hjälp, och jag använder det, ögonlysning samt patellaundersökning, och försöker sedan matcha dem med rätt partner både utseende mässigt och hälsomässigt. Mitt mål är att få sociala, glada, friska och vackra djur som ni kommer ha mycket glädje med.
        <br />
        Jag är utbildad hundinstruktör och erbjuder mina tjänster gratis till mina valpköpare såklart.
      </p>
      <p>
        Mina valpar ät så otroligt efterlängtade och betyder oerhört mycket att ni får rätt förutsättningar för att lyckas med er lilla vän.
      </p>
      <p>
        År 2020 startade jag även mitt katteri. Fokus på katter som inte fäller päls & på sociala och friska individer. Undrar du något så sänd gärna ett maj. Eller om du är intresserad av en kattunge eller hundvalp från mig och vill ställa dig på kö.
      </p>
      <p>
        Var noga med att beskriva ditt liv och vad du kommer erbjuda ett djur från mig. Dina eventuella erfarenheter av rasen och vad du önskar ert framtida liv ihop ska erbjuda.
      </p>
      <p>
        Med vänlig hälsning
        <br />
        Anna Östring
        <br />
        Nacka Hunddagis AB
        <br />
      </p>
    </div>
  </div>
);

export default About;
