/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { withAuthentication } from '../../components/session';

const INITIAL_STATE = {
  title: '',
  text: '',
  image: null,
  imageSrc: null,
  imagePreview: null,
  imageSrcForRemove: null,
  error: null,
  loading: false,
  saving: false,
  removing: false,
};

class PostFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      text: '',
      image: null,
      imageSrc: null,
      imagePreview: null,
      imageSrcForRemove: null,
      error: null,
      loading: false,
      saving: false,
      removing: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onImageAsFile = this.onImageAsFile.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.resetImage = this.resetImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.deleteImageThenPost = this.deleteImageThenPost.bind(this);
  }

  componentDidMount() {
    const { firebase, match } = this.props;
    this.setState({
      loading: true,
    });
    if (match.params.editid) {
      firebase.getPost(match.params.editid).once('value').then((snapshot) => {
        const post = snapshot.val();
        this.setState({
          ...post,
          imagePreview: post ? post.imageSrc : null,
          loading: false,
        });
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentWillUnmount() {
    const { firebase } = this.props;
    firebase.getPost().off();
  }

  onSubmit(event) {
    event.preventDefault();
    const {
      image, imageSrcForRemove,
    } = this.state;
    const { firebase } = this.props;
    this.setState({ saving: true });
    if (image && !imageSrcForRemove) {
      firebase.uploadImage(image).on('state_changed',
        () => {
        // takes a snap shot of the process as it is happening
        // console.log(snapShot);
        }, (err) => {
        // catches the errors
          console.log(err);
        }, () => {
          firebase.storage.ref('images').child(image.name).getDownloadURL().then((imageSrc) => {
            this.setState({ imageSrc }, () => {
              this.savePost();
            });
          });
        });
    } else if (image && imageSrcForRemove) {
      const imageRefFromUrl = firebase.storage.refFromURL(imageSrcForRemove);
      imageRefFromUrl.delete().then(() => {
        firebase.uploadImage(image).on('state_changed',
          () => {
            // takes a snap shot of the process as it is happening
            // console.log(snapShot);
          }, (err) => {
            // catches the errors
            console.log(err);
          }, () => {
            firebase.storage.ref('images').child(image.name).getDownloadURL().then((imageSrc) => {
              this.setState({ imageSrc }, () => {
                this.savePost();
              });
            });
          });
      }).catch((error) => {
        console.log(error);
      });
    } else if (!image && imageSrcForRemove) {
      const imageRefFromUrl = firebase.storage.refFromURL(imageSrcForRemove);
      imageRefFromUrl.delete().then(() => {
        this.savePost();
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.savePost();
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onImageAsFile(e) {
    const image = e.target.files[0];
    const imagePreview = URL.createObjectURL(e.target.files[0]);
    this.setState({ image, imagePreview });
  }

  savePost() {
    const { firebase, match, history } = this.props;
    const { title, text, imageSrc } = this.state;
    const date = Date.now();
    if (match.params.editid) {
      firebase
        .updatePost({
          title, date: parseInt(match.params.editid, 10), editDate: date, text, imageSrc,
        })
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          history.push(`/blog/post/${match.params.editid}`);
        })
        .catch((error) => {
          this.setState({ error });
        });
    } else {
      firebase
        .addPost({
          title, date, text, imageSrc,
        })
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          history.push(`/blog/post/${date}`);
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  }

  resetImage() {
    this.setState({ image: null, imagePreview: null });
  }

  deleteImage() {
    const { imageSrc } = this.state;
    this.setState({
      removing: true, imageSrcForRemove: imageSrc, imagePreview: null, image: null, imageSrc: null,
    });
  }

  deleteImageThenPost() {
    const { firebase } = this.props;
    const { imageSrc } = this.state;
    this.setState({ removing: true });
    const imageRefFromUrl = firebase.storage.refFromURL(imageSrc);
    imageRefFromUrl.delete().then(() => {
      this.deletePost();
    }).catch((error) => {
      this.deletePost();
      console.log(error);
    });
  }

  deletePost() {
    const { firebase, history, match } = this.props;
    this.setState({ removing: true });
    firebase.deletePost(match.params.editid).then(() => {
      history.push('/blog');
    }).catch((error) => {
      this.setState({ error, removing: false });
    });
  }

  render() {
    const { match, firebase } = this.props;
    const {
      loading, saving, removing, error, title, text, imagePreview, image,
    } = this.state;

    const isInvalid = title === '' || text === '';

    const noImage = !imagePreview && !image;
    if (firebase.auth.currentUser) {
      if (!loading || !saving || !removing) {
        return (
          <form className="col-12" onSubmit={this.onSubmit}>
            {match.params.editid ? <h1>Redigera inlägg</h1> : <h1>Skapa inlägg</h1>}
            <div className="form-group">
              <label htmlFor="postTitle">Rubrik</label>
              <input
                id="postTitle"
                className="form-control"
                type="text"
                onChange={this.onChange}
                placeholder="Rubrik..."
                name="title"
                value={title}
              />
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Bild</label>
                {imagePreview && (
                  <div className="form-group">
                    <img
                      className="img-fluid"
                      src={imagePreview}
                      alt={image ? image.name : 'Bild'}
                    />
                  </div>
                )}
                <div className="form-group">
                  <div className="custom-file">
                    <input type="file" disabled={imagePreview} className="custom-file-input" id="customFile" onChange={this.onImageAsFile} />
                    <label className="custom-file-label" htmlFor="customFile">{image ? image.name : 'Välj bild'}</label>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-action-rose"
                    disabled={noImage}
                    type="button"
                    onClick={image ? this.resetImage : this.deleteImage}
                  >
                    Radera bild
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="postText">Innehåll</label>
                  <textarea
                    id="postText"
                    className="form-control"
                    onChange={this.onChange}
                    placeholder="Innehåll..."
                    name="text"
                    value={text}
                    rows="10"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <button className="btn btn-action-rose mr-2" disabled={isInvalid || saving} type="submit">
                Spara
              </button>
              <Link className="btn btn-action-rose mr-2" to="/blog">Ångra</Link>
              {match.params.editid && (
                <button className="btn btn-action-rose" onClick={imagePreview ? this.deleteImageThenPost : this.deletePost} type="button">
                  Radera
                </button>
              )}
            </div>

            {error && <p>{error.message}</p>}
          </form>
        );
      }
      return (
        <>
          {saving && <p>Sparar inlägg...</p>}
          {loading && <p>Hämtar inlägg..</p>}
          {removing && <p>Raderar inlägg..</p>}
        </>
      );
    }
    return (<div className="col-12"><h1>Logga in för att se sidan</h1></div>);
  }
}

PostFormBase.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const PostForm = compose(
  withRouter,
  withAuthentication,
)(PostFormBase);

export default PostForm;
