import React from 'react';
import { NavLink } from 'react-router-dom';

const KennelNav = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <hr />
    </div>
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <p className="d-block d-lg-inline mr-2 mb-0"><span className="flag-icon flag-icon-se" /></p>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/start">Mina hundar</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/till-salu">Till Salu</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/rasbeskrivning">Rasbeskrivning</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/om">Om oss</NavLink>
      <br />
      <p className="d-block d-lg-inline mr-2 mb-0"><span className="flag-icon flag-icon-gb" /></p>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/my-dogs">My dogs</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/for-sale">For Sale</NavLink>
      <NavLink className="d-block d-lg-inline mr-2" to="/kennel/about-us">About us</NavLink>
    </div>
    <div className="col-12 col-sm-10 col-md-8 col-lg-8 subnav">
      <hr />
    </div>
  </div>
);

export default KennelNav;
