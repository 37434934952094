import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { withAuthentication } from '../../components/session';
import Post from '../../components/post';
import SignOutButton from './SignOut';
// import ScrollToTopOnMount from '../../components/ScrollTopOnMount';

class PostsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: null,
      error: null,
      loading: false,
      windowHeight: 0,
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    this.setState({
      loading: true,
      windowHeight: window.innerHeight,
    });

    firebase.getPosts().on('value', (snapshot) => {
      const postsObject = snapshot.val();
      try {
        let posts = Object.keys(postsObject).map((key) => ({
          ...postsObject[key],
          id: key,
        }));
        posts = posts.sort((a, b) => b.date - a.date);

        this.setState({
          posts,
          loading: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    const { firebase } = this.props;
    firebase.getPosts().off();
  }

  render() {
    const {
      posts, loading, error, windowHeight,
    } = this.state;
    const { firebase } = this.props;
    if (!loading) {
      return (
        <>
          {/* <ScrollToTopOnMount /> */}
          <div className="row d-flex justify-content-center">
            <div className="col-10 col-md-8 py-2">
              <h1>Aktuellt</h1>
            </div>
            {firebase.auth.currentUser
              && (
                <div className="col-10 col-md-8 py-2">
                  <Link className="btn btn-action-rose mr-2" to="/blog/new">Nytt inlägg</Link>
                  <SignOutButton />
                </div>
              )}
            {error && <div>{error}</div>}
            {posts && posts.map((post) => (
              <Post
                linkToPost
                truncateText
                key={post.date}
                heading={post.title}
                description={post.text}
                date={post.date}
                edited={post.editDate}
                imgSrc={post.imageSrc}
              >
                {firebase.auth.currentUser && (
                  <Link className="btn btn-action-rose" to={`/blog/post/edit/${post.id}`}>
                    <i className="fa fa-edit" />
                    {' '}
                    Ändra
                  </Link>
                )}
              </Post>
            ))}
          </div>
        </>
      );
    }
    return (
      <div className="row" style={{ height: windowHeight }}>
        <div className="col-12 text-center">
          <p>Hämtar inlägg..</p>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Hämtar inlägg..</span>
          </div>
        </div>
      </div>
    );
  }
}

PostsView.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
};

export default withAuthentication(PostsView);
