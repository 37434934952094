/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext, useMemo, useReducer, useContext,
} from 'react';

import * as AppActions from './actions';
import appReducer from './reducer';

const AppContext = createContext();

const INITIAL_STATE = {
  posts: null,
};

export function AppProvider(props) {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);
  const value = useMemo(() => [state, dispatch], [state]);
  return <AppContext.Provider value={value} {...props} />;
}

export function useAppState() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  const [state, dispatch] = context;

  const loadPosts = (payload) => dispatch({ type: AppActions.LOAD_POSTS, payload });

  return {
    state,
    dispatch,
    loadPosts,
  };
}
