import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DynamicPostsView from '../../components/DynamicPostsView';
import DynamicPostForm from '../../components/DynamicPostForm';

import StartView from './StartView';
import KennelNav from './KennelNav';
import About from './About';
import AboutEnglish from './AboutEnglish';
import RaceInfo from './RaceInfo';

// import SkkLinkLogo from '../../resources/webbannons-sjalvklart-350x400px.png';

const Kennel = () => (
  <div className="container-fluid kennel py-4">
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 py-2">
          <h1>Hundsida</h1>
        </div>
      </div>
      <KennelNav />
      <div className="row">
        <div className="col-12">
          <Switch>
            <Route exact path="/kennel/start">
              <StartView />
              <DynamicPostsView title="Mina hundar" area="kennel" lang="sv" />
            </Route>
            <Route exact path="/kennel/new">
              <DynamicPostForm area="kennel" />
            </Route>
            <Route path="/kennel/post/edit/:editid">
              <DynamicPostForm area="kennel" />
            </Route>
            <Route exact path="/kennel/till-salu">
              <DynamicPostsView title="Till salu" area="dogssale" lang="sv" />
            </Route>
            <Route exact path="/kennel/till-salu/new">
              <DynamicPostForm area="dogssale" />
            </Route>
            <Route exact path="/kennel/till-salu/post/edit/:editid">
              <DynamicPostForm area="dogssale" />
            </Route>
            <Route exact path="/kennel/om">
              <About />
            </Route>
            <Route exact path="/kennel/rasbeskrivning">
              <RaceInfo />
            </Route>
            <Route exact path="/kennel/about-us">
              <AboutEnglish />
            </Route>
            <Route exact path="/kennel/my-dogs">
              <DynamicPostsView title="My dogs" area="kennel" lang="en" />
            </Route>
            <Route exact path="/kennel/for-sale">
              <DynamicPostsView title="For Sale" area="dogssale" lang="en" />
            </Route>
          </Switch>
          {/* <div className="row">
            <div className="col-12 py-2 text-center">
              <a href="https://www.skk.se/sjalvklart" target="_blank" rel="noreferrer">
                <img src={SkkLinkLogo} className="skk-logo" alt="Svenska Kennelklubben" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
);

export default Kennel;
