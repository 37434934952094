import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { withAuthentication } from '../../components/session';
import SignInForm from './SignIn';
import SignOutButton from './SignOut';

const AdminView = ({ firebase }) => {
  if (firebase.auth.currentUser) {
    return (
      <div className="row">
        <div className="col-12">
          <Link className="btn btn-action-rose mr-2" to="/blog/new">Nytt inlägg</Link>
          <SignOutButton />
        </div>
      </div>
    );
  }
  return <SignInForm />;
};

AdminView.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
};

export default withAuthentication(AdminView);
