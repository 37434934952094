import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/firebase';

const INITIAL_STATE = {
  email: '',
  password: '',
  loading: false,
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    const { firebase, history } = this.props;
    this.setState({ loading: true });
    firebase
      .doSignInWithEmailAndPassword(email.trim(), password)
      .then(() => {
        history.push('/blog');
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const {
      email, password, loading, error,
    } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <h1>Logga in</h1>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <input
              className="form-control"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <input
              name="password"
              className="form-control"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Lösenord"
            />
          </div>
        </div>
        <button
          className="btn btn-action-rose"
          disabled={isInvalid || loading}
          type="submit"
        >
          Logga in
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

SignInFormBase.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInForm;
