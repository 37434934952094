import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

// PROD
const firebaseConfig = {
  apiKey: 'AIzaSyBeh_KmwmJ1Y9QIJ2nUI2jS4IbolSDb-2g',
  authDomain: 'nacka-hunddagis.firebaseapp.com',
  databaseURL: 'https://nacka-hunddagis.firebaseio.com',
  projectId: 'nacka-hunddagis',
  storageBucket: 'nacka-hunddagis.appspot.com',
  messagingSenderId: '363056323274',
  appId: '1:363056323274:web:c8122995df6a12be393233',
  measurementId: 'G-5Z450V0NE8',
};

// TEST
// const firebaseConfig = {
//   apiKey: 'AIzaSyAhaYP6Yl7_RTtGRk3NqJnnAqJRCUCQh0w',
//   authDomain: 'nacka-hunddagis-test.firebaseapp.com',
//   databaseURL: 'https://nacka-hunddagis-test-default-rtdb.europe-west1.firebasedatabase.app',
//   projectId: 'nacka-hunddagis-test',
//   storageBucket: 'nacka-hunddagis-test.appspot.com',
//   messagingSenderId: '327807970925',
//   appId: '1:327807970925:web:2342ff47ca025464cad964',
//   measurementId: 'G-3DWF0GQFJV',
// };

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    app.analytics();
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email);
  }

  doPasswordUpdate(password) {
    return this.auth.currentUser.updatePassword(password);
  }

  // *** Blog API ***

  getPost(id) {
    return this.db.ref(`posts/${id}`);
  }

  getPosts() {
    return this.db.ref('posts');
  }

  addPost(post) {
    return this.db.ref(`posts/${post.date}`).set(post);
  }

  updatePost(post) {
    return this.db.ref(`posts/${post.date}`).set(post);
  }

  deletePost(id) {
    return this.db.ref(`posts/${id}`).remove();
  }

  uploadImage(image) {
    return this.storage.ref(`/images/${image.name}`).put(image);
  }

  // *** Dynamic Posts API ***

  getDynamicPost(area, id) {
    return this.db.ref(`${area}/${id}`);
  }

  getDynamicPosts(area) {
    return this.db.ref(`${area}`);
  }

  addDynamicPost(area, post) {
    return this.db.ref(`${area}/${post.date}`).set(post);
  }

  updateDynamicPost(area, post) {
    return this.db.ref(`${area}/${post.date}`).set(post);
  }

  deleteDynamicPost(area, id) {
    return this.db.ref(`${area}/${id}`).remove();
  }

  uploadDynamicImage(area, image) {
    return this.storage.ref(`/images/${area}/${image.name}`).put(image);
  }
}

export default Firebase;
