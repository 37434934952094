/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/firebase';

const onSignOut = (firebase, history) => {
  firebase.auth.signOut().then(() => {
    history.push('/blog');
  }).catch((error) => {
    console.log('error', error);
  });
};

const SignOutButtonBase = ({ firebase, history }) => (
  <button className="btn btn-action-rose" type="button" onClick={() => onSignOut(firebase, history)}>
    Logga ut
  </button>
);

const SignOutButton = compose(
  withRouter,
  withFirebase,
)(SignOutButtonBase);

export default SignOutButton;
