/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { withAuthentication } from '../../components/session';

const Footer = ({ firebase }) => (
  <footer>
    <div className="container py-4">
      <div className="row d-flex justify-content-center">
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-lg-4">
              <p><b>Nacka Hunddagis AB</b></p>
              <p>
                <i className="fab fa-instagram" />
                {' '}
                <a href="https://www.instagram.com/italiangreyhoundnackahunddagis/">Nacka Hunddagis</a>
              </p>
              <p>
                <i className="fab fa-facebook" />
                {' '}
                <a href="https://www.facebook.com/Nackahunddagis/">Nacka Hunddagis</a>
              </p>
              <p>Org.nr: 559170-8671</p>
            </div>
            <div className="col-12 col-lg-4">
              <p>
                Hitta hit:
                {' '}
                <a href="https://goo.gl/maps/wyg1wDBCjHPztx7v8">Apportvägen</a>
              </p>
            </div>
            <div className="col-12 col-lg-4">
              <p>
                Dagis:
                {' '}
                <a href="tel:073-9667909">073-9667909</a>
              </p>
              <p>
                Mobil:
                {' '}
                <a href="tel:070-4995235">070-4995235</a>
              </p>
              <p>
                E-post:
                {' '}
                <a href="mailto:nacka.hunddagis31@outlook.com">nacka.hunddagis31@outlook.com</a>
              </p>
              <p>
                {firebase.auth.currentUser ? <Link to="/blog">Admin (Inloggad)</Link> : <Link to="/blog/admin">Admin</Link>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default withAuthentication(Footer);
