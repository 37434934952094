import * as AppActions from './actions';

const appReducer = (state, action) => {
  switch (action.type) {
  case AppActions.LOAD_POSTS:
    return {
      ...state,
      posts: action.payload,
    };
  default:
    console.log('action not implemented', action);
    return state;
  }
};

export default appReducer;
