import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { withAuthentication } from '../session';
import Post from '../post';
// import ScrollToTopOnMount from '../ScrollTopOnMount';

const getPostUrl = (area, method, postId) => {
  if (method === 'new') {
    if (area === 'dogssale') {
      return `/kennel/till-salu/${method}`;
    }
    if (area === 'catssale') {
      return `/katteri/till-salu/${method}`;
    }
    return `/${area}/${method}`;
  }
  if (method === 'edit') {
    if (area === 'dogssale') {
      return `/kennel/till-salu/post/${method}/${postId}`;
    }
    if (area === 'catssale') {
      return `/katteri/till-salu/post/${method}/${postId}`;
    }
    return `/${area}/post/${method}/${postId}`;
  }
  return `/${area}`;
};

const DynamicPostsView = ({
  firebase, title, area, lang,
}) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    setLoading(true);

    const getPosts = async () => {
      const postsRef = await firebase.getDynamicPosts(area);
      const snapshot = await postsRef.once('value');
      const value = snapshot.val();
      let unSortedPosts;
      let displayPosts = [];
      if (value) {
        unSortedPosts = Object.keys(value).map((key) => ({
          ...value[key],
          id: key,
        }));
        displayPosts = unSortedPosts.sort((a, b) => b.date - a.date);
      }
      if (lang === 'en') {
        const filteredPosts = displayPosts.filter((post) => post.engTitle && post.engTitle.length > 0);
        displayPosts = filteredPosts;
      }
      setPosts(displayPosts);
      setLoading(false);
    };
    getPosts();

    return () => {
      setWindowHeight(0);
      setLoading(false);
      firebase.getDynamicPosts().off();
    };
  }, []);

  if (!loading) {
    return (
      <>
        {/* <ScrollToTopOnMount /> */}
        <div className="row d-flex justify-content-center">
          {title && (
            <div className="col-12 col-sm-10 col-md-8 py-2">
              <h2>{title}</h2>
            </div>
          )}
          {firebase.auth.currentUser
            && (
              <div className="col-12 col-sm-10 col-md-8 py-2">
                <Link className="btn btn-action-rose mr-2" to={getPostUrl(area, 'new')}>Lägg till</Link>
              </div>
            )}
          {posts && posts.map((post) => (
            <Post
              key={post.date}
              heading={lang !== 'en' ? post.title : post.engTitle}
              description={lang !== 'en' ? post.text : post.engText}
              links={post.links}
              images={[
                {
                  id: 1,
                  src: post.imageSrc,
                },
                {
                  id: 2,
                  src: post.imageTwoSrc,
                },
              ]}
            >
              {firebase.auth.currentUser && (
                <Link className="btn btn-action-rose" to={getPostUrl(area, 'edit', post.id)}>
                  <i className="fa fa-edit" />
                  {' '}
                  Ändra
                </Link>
              )}
            </Post>
          ))}
        </div>
      </>
    );
  }
  return (
    <div className="row" style={{ height: windowHeight }}>
      <div className="col-12 text-center">
        <p>Laddar..</p>
        <div className="spinner-grow" role="status">
          <span className="sr-only">Laddar..</span>
        </div>
      </div>
    </div>
  );
};

DynamicPostsView.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
  area: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DynamicPostsView.defaultProps = {
  title: null,
};

export default withAuthentication(DynamicPostsView);
