import React from 'react';

const Dogcare = () => (
  <div className="row">
    <div className="col-12">
      <h2>En dag på mitt dagis.</h2>
      <p>
        Vi börjar dagen med att hälsa kunder välkomna kl. 7 på morgonen och tända ett ljus i hallen, tycker det blir så mysigt stämning av ljus.
      </p>
      <p>
        Vissa kunder har eventuellt redan lämnat in sin hund efter överenskommelse.
      </p>
      <p>
        Fram till 10 tar vi emot dagishundarna och placerar dom där deras aktivitetsnivå passar in.
      </p>
      <p>
        Vissa vill bums ut rastgården som erbjuder lek med kompisarna, trots alla möjliga väder, och vissa vill ha en skön bädd eller soffa inne i värmen i väntan på promenaden eller ute i lugna rastgården som erbjuder spännande utsikt.
      </p>
      <p>
        Vid 10 börjar vi vår första promenad med dom ivrigaste och mest krävande hundarna. Vår filosofi är att hundarna ska få en riktigt lång promenad som ger dom en känsla av harmoni och lugn i kroppen. Motion är så viktigt för hälsan. En promenad är ca 7 km lång vi går med max 10 st. per gång, ofta färre. Tycker man får en mycket mysigare promenad med ca 7–8 st. och det gör även det enklare att alla får uträtta sina behov i sin takt.
      </p>
      <p>
        När den första promenaden är klar så går vi ut med nästa, och så håller vi på ända tills alla är rastade och nöjda. Vi har även en kortare promenad för hundarna som mår bäst av det.
      </p>
      <p>
        Vid 15 ser vi till att alla hundar får en sista rastning innan hämtning och ett godissök.
      </p>
      <p>
        Efter 15 är er hund på plats och ni kan hämta. Behöver ni hämta tidigare fixar vi såklart det, bara säg till.
      </p>
      <p>
        Vi aktiverar hundarna med olika slags tugg och godissök emellan lek och promenader.
      </p>
      <p>
        Fram till 18 är ni sedan välkomna att hämta. Skulle något ske i trafiken och ni kommer för sent så ring oss och vi löser det utan nån extra avgift. Så ingen stress hos oss,ni och hundarna är viktiga för oss och vi finns för att underlätta er vardag. Speciellt om det kör ihop sig.
      </p>
      <p>
        Vi som arbetar här trivs mycket bra ihop och vi är ett sammansvetsat team.
      </p>
      <p>
        Mitt dagis är = Harmoni.
      </p>
      <p>
        Hoppas ni gillar vad ni läst och att vi ses.
      </p>
      <p>
        Vi är givetvis godkända av Jordbruksverket, länsstyrelsen och innehåller F-skatt sedel.
      </p>
      <p />
      <p>
        Med vänliga hälsningar
        <br />
        Team Nacka hunddagis
        <br />
      </p>
    </div>
  </div>
);

export default Dogcare;
