/* eslint-disable react/prop-types */
import React from 'react';
import SignInForm from './SignIn';
import { withAuthentication } from '../../components/session';
import AdminView from './AdminView';

const Admin = ({ firebase }) => (
  <div className="row">
    <div className="col-12">
      {firebase.auth.currentUser
        ? <AdminView />
        : <SignInForm />}
    </div>
  </div>
);

export default withAuthentication(Admin);
