import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Admin from './Admin';
import PostsView from './PostsView';
import PostView from './PostView';
import PostForm from './PostForm';

const Blog = () => (
  <div className="container-fluid pt-4">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Switch>
            <Route exact path="/blog">
              <PostsView />
            </Route>
            <Route exact path="/blog/post/:id">
              <PostView />
            </Route>
            <Route exact path="/blog/new">
              <PostForm />
            </Route>
            <Route path="/blog/post/edit/:editid">
              <PostForm />
            </Route>
            <Route path="/blog/admin">
              <Admin />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  </div>
);

export default Blog;
