/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { withAuthentication } from '../session';

const INITIAL_STATE = {
  title: '',
  text: '',
  engTile: '',
  engText: '',
  image: null,
  imageSrc: null,
  imagePreview: null,
  imageSrcForRemove: null,
  imageTwo: null,
  imageTwoSrc: null,
  imageTwoPreview: null,
  imageTwoSrcForRemove: null,
  links: [],
};

const getBackUrl = (area) => {
  if (area === 'dogssale') {
    return '/kennel/till-salu';
  }
  if (area === 'catssale') {
    return '/katteri/till-salu';
  }
  return `/${area}/start`;
};

const DynamicPostFormBase = (props) => {
  const {
    firebase, match, area, history,
  } = props;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [removing, setRemoving] = useState(false);
  // const [error, setError] = useState(null);
  const [dynamicPostState, setDynamicPostState] = useState(INITIAL_STATE);

  const [newLink, setNewLink] = useState({ text: '', href: '' });

  useEffect(() => {
    setLoading(true);
    if (match.params.editid) {
      firebase.getDynamicPost(area, match.params.editid).once('value').then((snapshot) => {
        const post = snapshot.val();
        setDynamicPostState({
          ...post,
          imagePreview: post ? post.imageSrc : null,
          imageTwoPreview: post ? post.imageTwoSrc : null,
        });
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    return () => {
      firebase.getDynamicPost().off();
    };
  }, []);

  const savePost = async (newImageSrc = null, newImageTwoSrc = null) => {
    const {
      title, text, engTitle, engText, imageSrc = null, imageTwoSrc = null, links = [],
    } = dynamicPostState;

    const date = Date.now();
    if (match.params.editid) {
      const updatedPost = await firebase
        .updateDynamicPost(area, {
          date: parseInt(match.params.editid, 10),
          editDate: date,
          title,
          text,
          engTitle,
          engText,
          imageSrc: !newImageSrc ? imageSrc : newImageSrc,
          imageTwoSrc: !newImageTwoSrc ? imageTwoSrc : newImageTwoSrc,
          links,
        });
      console.log('updatedPost', updatedPost);
      setDynamicPostState(INITIAL_STATE);
      history.goBack();
    } else {
      const addedPost = await firebase
        .addDynamicPost(area, {
          date,
          title,
          text,
          engTitle,
          engText,
          imageSrc: newImageSrc,
          imageTwoSrc: newImageTwoSrc,
          links,
        });
      console.log('addedPost', addedPost);

      setDynamicPostState(INITIAL_STATE);
      history.goBack();
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const {
      image, imageTwo,
    } = dynamicPostState;

    setSaving(true);

    let imageSrc;
    let imageTwoSrc;

    if (image || imageTwo) {
      if (image) {
        const uploadImage = await firebase.uploadDynamicImage(area, image);
        console.log('uploadImage', uploadImage);

        imageSrc = await firebase.storage.ref(`images/${area}`).child(image.name).getDownloadURL();
        console.log('imageSrc', imageSrc);

        setDynamicPostState({
          ...dynamicPostState,
          imageSrc,
        });
      }
      if (imageTwo) {
        const uploadImageTwo = await firebase.uploadDynamicImage(area, imageTwo);
        console.log('uploadImageTwo', uploadImageTwo);

        imageTwoSrc = await firebase.storage.ref(`images/${area}`).child(imageTwo.name).getDownloadURL();
        console.log('imageTwoSrc', imageTwoSrc);

        setDynamicPostState({
          ...dynamicPostState,
          imageTwoSrc,
        });
      }
    }
    savePost(imageSrc, imageTwoSrc);
  };

  const onChange = (event) => {
    setDynamicPostState({
      ...dynamicPostState,
      [event.target.name]: event.target.value,
    });
  };

  const onImageAsFile = (e) => {
    const image = e.target.files[0];
    const imagePreview = URL.createObjectURL(e.target.files[0]);
    setDynamicPostState({
      ...dynamicPostState,
      image,
      imagePreview,
    });
  };

  const onImageTwoAsFile = (e) => {
    const image = e.target.files[0];
    const imagePreview = URL.createObjectURL(e.target.files[0]);
    setDynamicPostState({
      ...dynamicPostState,
      imageTwo: image,
      imageTwoPreview: imagePreview,
    });
  };

  const resetImage = () => {
    setDynamicPostState({
      ...dynamicPostState,
      image: null,
      imagePreview: null,
    });
  };
  const resetImageTwo = () => {
    setDynamicPostState({
      ...dynamicPostState,
      imageTwo: null,
      imageTwoPreview: null,
    });
  };

  const deleteImage = async () => {
    const { imageSrc } = dynamicPostState;
    setRemoving(true);
    try {
      const imageRefFromUrl = firebase.storage.refFromURL(imageSrc);
      const removedImage = await imageRefFromUrl.delete();
      console.log('removed image 1', removedImage);
    } catch (error) {
      console.log('Error Image 1', error);
    }

    try {
      const date = Date.now();
      const updatedPost = await firebase.updateDynamicPost(area, {
        ...dynamicPostState,
        date: parseInt(match.params.editid, 10),
        editDate: date,
        imageSrc: null,
      });

      console.log('updatedPost', updatedPost);
    } catch (error) {
      console.log('Error Image 1', error);
    }

    setDynamicPostState({
      ...dynamicPostState,
      imagePreview: null,
      image: null,
      imageSrc: null,
    });
    setRemoving(false);
  };

  const deleteImageTwo = async () => {
    const { imageTwoSrc } = dynamicPostState;
    setRemoving(true);
    try {
      const imageRefFromUrl = firebase.storage.refFromURL(imageTwoSrc);
      const removedImage = await imageRefFromUrl.delete();
      console.log('removed image 2', removedImage);
    } catch (error) {
      console.log('Error Image 2', error);
    }

    try {
      const date = Date.now();
      const updatedPost = await firebase.updateDynamicPost(area, {
        ...dynamicPostState,
        date: parseInt(match.params.editid, 10),
        editDate: date,
        imageTwoSrc: null,
      });
      console.log('updatedPost', updatedPost);
    } catch (error) {
      console.log('Error Image 2', error);
    }

    setDynamicPostState({
      ...dynamicPostState,
      imageTwoPreview: null,
      imageTwo: null,
      imageTwoSrc: null,
    });
    setRemoving(false);
  };

  const deletePost = async () => {
    setRemoving(true);

    if (dynamicPostState.imageSrc) {
      try {
        const imageRefFromUrl = firebase.storage.refFromURL(dynamicPostState.imageSrc);
        const image = await imageRefFromUrl.delete();
        console.log('image', image);
      } catch (error) {
        console.log('Error Image 1: ', error);
      }
    }

    if (dynamicPostState.imageTwoSrc) {
      try {
        const imageTwoRefFromUrl = firebase.storage.refFromURL(dynamicPostState.imageTwoSrc);
        const imageTwo = await imageTwoRefFromUrl.delete();
        console.log('imageTwo', imageTwo);
      } catch (error) {
        console.log('Error  Image 2: ', error);
      }
    }

    const post = await firebase.deleteDynamicPost(area, match.params.editid);
    console.log(post);

    history.push(getBackUrl(area));
    setRemoving(false);
  };

  const addLink = () => {
    const previousLinks = dynamicPostState?.links || [];
    const updatedLinks = [
      ...previousLinks,
      newLink,
    ];
    setDynamicPostState({
      ...dynamicPostState,
      links: updatedLinks,
    });
    setNewLink({ text: '', href: '' });
  };

  const removeLink = (index) => {
    const updatedLinks = dynamicPostState.links.reduce((links, currentValue, currentIndex) => {
      if (index !== currentIndex) {
        return links.concat(currentValue);
      }
      return links;
    }, []);
    setDynamicPostState({
      ...dynamicPostState,
      links: updatedLinks,
    });
  };

  const onChangeLinkText = (e) => {
    setNewLink({
      ...newLink,
      text: e.target.value,
    });
  };
  const onChangeLinkHref = (e) => {
    setNewLink({
      ...newLink,
      href: e.target.value,
    });
  };

  const noLinkInfo = newLink.text === '' || newLink.href === '';

  const isInvalid = dynamicPostState.title === '' || dynamicPostState.text === '';
  const noImage = !dynamicPostState.imagePreview && !dynamicPostState.image;
  const noImageTwo = !dynamicPostState.imageTwoPreview && !dynamicPostState.imageTwo;

  if (firebase.auth.currentUser) {
    if (!loading || !saving || !removing) {
      return (
        <form className="col-12" onSubmit={onSubmit}>
          {match.params.editid ? <h1>Redigera inlägg</h1> : <h1>Skapa inlägg</h1>}
          <div className="form-group">
            <label htmlFor="postTitle">Rubrik</label>
            <input
              id="postTitle"
              className="form-control"
              type="text"
              onChange={onChange}
              placeholder="Rubrik..."
              name="title"
              value={dynamicPostState.title}
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <label>Bild</label>
              {dynamicPostState.imagePreview && (
                <div className="form-group">
                  <img
                    className="img-fluid"
                    src={dynamicPostState.imagePreview}
                    alt={dynamicPostState.image ? dynamicPostState.image.name : 'Bild'}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="custom-file">
                  <input
                    type="file"
                    disabled={dynamicPostState.imagePreview}
                    className="custom-file-input"
                    id="customFile"
                    onChange={onImageAsFile}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                  >
                    {dynamicPostState.image ? dynamicPostState.image.name : 'Välj bild'}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-action-rose"
                  disabled={noImage}
                  type="button"
                  onClick={dynamicPostState.image ? resetImage : deleteImage}
                >
                  Radera bild
                </button>
              </div>
            </div>
            {/* Image 2 */}
            <div className="col-12 col-md-6">
              <label>Bild 2</label>
              {dynamicPostState.imageTwoPreview && (
                <div className="form-group">
                  <img
                    className="img-fluid"
                    src={dynamicPostState.imageTwoPreview}
                    alt={dynamicPostState.imageTwo ? dynamicPostState.imageTwo.name : 'Bild'}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="custom-file">
                  <input
                    type="file"
                    disabled={dynamicPostState.imageTwoPreview}
                    className="custom-file-input"
                    id="customFile"
                    onChange={onImageTwoAsFile}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                  >
                    {dynamicPostState.imageTwo ? dynamicPostState.imageTwo.name : 'Välj bild'}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-action-rose"
                  disabled={noImageTwo}
                  type="button"
                  onClick={dynamicPostState.imageTwo ? resetImageTwo : deleteImageTwo}
                >
                  Radera bild
                </button>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="linkTitle">Länk text</label>
              <input
                id="linkTitle"
                className="form-control"
                type="text"
                onChange={onChangeLinkText}
                placeholder="Länk text"
                name="link-text"
                value={newLink.text}
              />
            </div>
            <div className="form-group col">
              <label htmlFor="linkHref">Länk</label>
              <input
                id="linkHref"
                className="form-control"
                type="text"
                onChange={onChangeLinkHref}
                placeholder="Länk"
                name="link-href"
                value={newLink.href}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <button
                className="btn btn-action-rose"
                disabled={noLinkInfo}
                type="button"
                onClick={addLink}
              >
                Lägg till länk
              </button>
            </div>
          </div>
          {dynamicPostState?.links?.length > 0 && (
            <div className="row mt-2">
              <div className="col-12">
                <p>Länkar</p>
              </div>
              <div className="col-12">
                {dynamicPostState.links.map((link, i) => (
                  <p key={`${i}_link`}>
                    <span className="mr-2"><a href={link.href} target="_blank" rel="noreferrer">{link.text}</a></span>
                    <span className="mr-2">{link.href}</span>
                    <button
                      className="btn btn-action-rose"
                      type="button"
                      onClick={() => removeLink(i)}
                    >
                      Radera
                    </button>
                  </p>
                ))}
              </div>
            </div>
          )}
          <div className="row mt-3">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="postText">Beskrivning</label>
                <textarea
                  id="postText"
                  className="form-control"
                  onChange={onChange}
                  placeholder="Innehåll..."
                  name="text"
                  value={dynamicPostState.text}
                  rows="10"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="engTitle">Rubrik (engelska)</label>
                <input
                  id="engTitle"
                  className="form-control"
                  type="text"
                  onChange={onChange}
                  placeholder="Rubrik... (engelska)"
                  name="engTitle"
                  value={dynamicPostState.engTitle}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="postEngText">Beskrivning (engelska)</label>
                <textarea
                  id="postEngText"
                  className="form-control"
                  onChange={onChange}
                  placeholder="Innehåll... (engelska)"
                  name="engText"
                  value={dynamicPostState.engText}
                  rows="10"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group">
            <button
              className="btn btn-action-rose mr-2"
              disabled={isInvalid || saving}
              type="submit"
            >
              Spara
            </button>
            <Link className="btn btn-action-rose mr-2" to={getBackUrl(area)}>Tillbaka</Link>
            {match.params.editid && (
              <button
                className="btn btn-action-rose"
                onClick={deletePost}
                type="button"
              >
                Radera
              </button>
            )}
          </div>
        </form>
      );
    }
    return (
      <>
        {saving && <p>Sparar inlägg...</p>}
        {loading && <p>Hämtar inlägg..</p>}
        {removing && <p>Raderar inlägg..</p>}
      </>
    );
  }
  return (<div className="col-12"><h1>Logga in för att se sidan</h1></div>);
};

DynamicPostFormBase.propTypes = {
  firebase: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  area: PropTypes.string.isRequired,
};

const DynamicPostForm = compose(
  withRouter,
  withAuthentication,
)(DynamicPostFormBase);

export default DynamicPostForm;
