import React from 'react';

const About = () => (
  <div className="row d-flex justify-content-center">
    <div className="col-12 col-sm-10 col-md-8 col-lg-8">
      <h2>Om oss</h2>
      <p>
        Hej
        <br />
        <br />
        Vill berätta om hur vi bedriver vår uppfödning och hur katter och hundar lever hos oss.
        <br />
        <br />
        Jag älskar att ta hand om djur och har alltid gjort, jag var en sådan som tyckte synd om små bananflugor som dödades och maskar som låg på gatan, och räddade dem.
        <br />
        <br />
        Maskar räddar jag än idag från gatan.
        <br />
        <br />
        Ett av mina första arbeten var i djuraffär med både kaniner, marsvin , reptiler, fåglar och fiskar, jag var då enbart 16 år, men bodde i egen lägenhet med mina 2 hundar som jag tävlade lydnad och bruks med samt lyckades utbildade min Schäfer till tjänstehund, underbara minnen.
        <br />
        <br />
        I djuraffärerna lärde jag mig mycket under mina dryga 9 år av lite mer än heltidsarbete, då djuren behöver skötsel även långhelger.
        <br />
        <br />
        Har idag 3 barn och under deras första levnadsår hade jag inga djur ett tag, en väldigt konstig känsla men nödvändig då jag var ensam med nyfödd och 2 barn och det kräver en del, men jag klarade det jättebra, och samtidigt rullade mitt företag på som jag startat år 2001.
        <br />
        <br />
        Att ta hand om hundar på Nacka Hunddagis, Även Nackas första hunddagis!
        <br />
        <br />
        Idag är bor dom äldsta barnen där dom vill, hos mig eller deras far som också är en stor djurvän.
        <br />
        <br />
        Barnen har ärvt vår kärlek till djuren och vi har utöver hundar och katter en häst vi sköter om och marsvin.
        <br />
        <br />
        Våran Yngsta dotter bor varannan vecka hos oss och går i Särskola.
        <br />
        <br />
        Vi bortprioriterar mycket i våra liv för att vara hos djuren och ge dom stimulerande dagar, men vi får ju så mycket kärlek och glädje tillbaka.
        <br />
        <br />
        Vi älskar att se djur lyckliga, mår dom bra mår vi bra.
        <br />
        <br />
        Vi aktiverar katter och hundar med nya aktivitetsleksaker ofta och vi har 2 utomhusrastgårdar till katterna och planerar en tredje så dom tryggt kan vara utomhus och leka, samt få deras behov av att klättra i olika höjder tillgodosett och motion.
        <br />
        <br />
        Vi bor i hus och har gott om utrymme, och en liten tomt.
        <br />
        <br />
        Bra mat är viktigt, och vi ger våra djur varierad mat, aldrig enbart torrfoder.
        <br />
        <br />
        Blöt mat minst 2 gånger om dagen, tugg ben till vovvarna dagligen efter promenaden och godis till katterna.
        <br />
        <br />
        Innan jag äter frukost ska djuren ha sin mat och självklart städningen också.
        <br />
        <br />
        Jag brukar säga jag är en katt och hundbonde då det krävs att man städar noga för att både djur och vi ska mår bra och det varje dag.
        <br />
        <br />
        Jag är lite av en pedant med golv och mycket, men trivs med det.
        <br />
        <br />
        Alla som är intresserade av att köpa djur av oss är välkommen att hälsa på oss, jag är stolt över att visa hur mina djur lever och hur mysiga dom är.
        <br />
        <br />
        Vid intresse av ett litet liv hos oss vill vi veta hur en dag hos er ser ut för hunden eller katten samt er erfarenhet av att sköta om ett eller flera djur.
        <br />
        <br />
        Ni får väldigt gärna visa ert intresse tidigt, så vi kan se till att ni får just er favorit.
        <br />
        <br />
        Vi vill också kunna följa er så vi vet att djuret har det bra när dom flyttat och undrar ni om något så hoppas jag ni känner att ni vill vända er till mig och fråga , jag vill alltid finnas där för mina kunder och ungar.
        <br />
        <br />
        Jag är utbildad hundinstruktör och har arbetat på veterinärklinik.
        <br />
        <br />
        Djurförsäljning är inget man gör en ekonomisk vinst på.
        <br />
        <br />
        Det handlar enbart om en stor kärlek till djuren, ett brinnande intresse och livsstil.
        <br />
        <br />
        Jag följer givetvis Sveriges djurskyddslag, tillstånd från Länsstyrelsen och är medlem i Sverak samt Svenska kennelklubben och då måste man som säljare använda deras avtal vid försäljning som i sin tur är en trygghet för kunden.
        <br />
        <br />
        Jag hoppas vi ses och undrar ni något som jag missat så är det bara att pm mig på messenger eller via mail.
        <br />
        <br />
      </p>

      <p>
        Med vänliga hälsningar
        <br />
        Anna med familj.
      </p>

    </div>
  </div>
);

export default About;
